import { Box, Text } from "@chakra-ui/react";

interface Props {
    versionText: string;
}

const Footer = ({ versionText }: Props) => {
    return (
        <Box
            as="footer"
            textAlign="center"
            py={2}
            position="relative"
            zIndex={0}
            borderTop="1px solid var(--euka-colors-gray-300)"
        >
            <Text color="gray.500" fontSize={10} fontFamily="secondary">
                &copy; {versionText}
            </Text>
        </Box>
    );
};

export default Footer;
